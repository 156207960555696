'use strict'
const _ = require('lodash')

const REPEATER_DELIMITER = '__'
const REFERRED_POINTER_DELIMITER = '_r_'

const getUniqueDisplayedId = (originalId, itemId) => originalId + REPEATER_DELIMITER + itemId
const updateCompIdInPointersMap = ({id}, itemId, updatePointerMap) => updatePointerMap(id, getUniqueDisplayedId(id, itemId))
const isRepeatedComponent = compId => !!getRepeaterItemId(compId)
const getRepeaterItemId = uniqueItemId => _.isString(uniqueItemId) ? uniqueItemId.split(REPEATER_DELIMITER)[1] : undefined
const getRepeaterTemplateId = uniqueItemId => _.isString(uniqueItemId) ? uniqueItemId.split(REPEATER_DELIMITER)[0] : undefined

const isRefferedId = id => _.includes(id, REFERRED_POINTER_DELIMITER)

const isDisplayedOnlyComponent = compId => isRepeatedComponent(compId) || isRefferedId(compId)

const uniquePropertyMapperFunctions = {
    dataQuery: getUniqueDisplayedId,
    designQuery: getUniqueDisplayedId,
    id: getUniqueDisplayedId,
    parent: getUniqueDisplayedId,
    layout: value => _.cloneDeep(value),
    components: (value, itemId, updatePointerMap) =>
        _.map(value, child => _.isString(child) ? getUniqueDisplayedId(child, itemId) : getUniqueStructure(child, itemId, updatePointerMap))
}

function getUniqueStructure(structure, itemId, updatePointerMap = _.noop) {
    updateCompIdInPointersMap(structure, itemId, updatePointerMap)
    return _.mapValues(structure, (value, property) => _.invoke(uniquePropertyMapperFunctions, property, value, itemId, updatePointerMap) || value)
}

function getUniqueFlatStructureMap(compsMap, itemId, updatePointerMap = _.noop) {
    return _(compsMap)
        .map(comp => getUniqueStructure(comp, itemId, updatePointerMap))
        .keyBy('id')
        .value()
}

const originalPropertyMapperFunctions = {
    dataQuery: getRepeaterTemplateId,
    designQuery: getRepeaterTemplateId,
    id: getRepeaterTemplateId,
    layout: value => _.cloneDeep(value),
    components: value => _.map(value, child => child.id ? getOriginalStructure(child) : getRepeaterTemplateId(child))
}

function getOriginalStructure(itemStructure) {
    return _(itemStructure)
        .omit('parent')
        .mapValues((value, property) => _.invoke(originalPropertyMapperFunctions, property, value) || value)
        .value()
}

const isRefPointer = p => isRefferedId(p.id)

module.exports = {
    getUniqueFlatStructureMap,
    getUniqueStructure,
    getOriginalStructure,
    isRepeatedComponent,
    isDisplayedOnlyComponent,
    getRepeaterItemId,
    getRepeaterTemplateId,
    getUniqueDisplayedId,
    isRefPointer,
    //Keeping these for backward compatibility while refactoring
    isDisplayedComponent: isRepeatedComponent,
    getItemId: getRepeaterItemId,
    getOriginalId: getRepeaterTemplateId
}

